import requestAjax from './requestAjax';

function AjaxModal(button, options){

	var API = {},
		setting = {};

	function getOptions() {
		if (!options) { options = {} };

		setting.onLoad = options.onLoad || function(){};
	};

	function init(){
		getOptions();

		setting.isLoad = false;

		setting.modalSelector = button.getAttribute('data-drop-toggle');

		if(setting.modalSelector == ""){
			console.error('link href is empty');
			return;
		}

		setting.modal = document.getElementById(setting.modalSelector);

		if(!setting.modal){
			console.error('modal container is undefined');
			return
		}

		setting.modalContent = setting.modal.querySelector('[data-modal-content]');

		if(!setting.modalContent){
			console.error('modal content is undefined');
			return
		}

		setEvents();
	}

	function load(){
		if(setting.isLoad){return}

		var url = button.getAttribute('href'),
			options = {};

		if(url == ""){return}

		options.url = url;

		options.onSuccess = onSuccess;
		options.onError = onError;

		setLoadState();
		requestAjax(options);
	}

	function setLoadState(){
		setting.isLoad = true;
		setting.modal.classList.add('is-overlay');
	}

	function setDefaultState(){
		setting.isLoad = false;
		setting.modal.classList.remove('is-overlay');
	}


	function onSuccess(response){
		setDefaultState();
		setting.modalContent.innerHTML = response.data.html;

		setting.onLoad(setting.modal);
		fireEvent();
	}
	function onError(response){
		setDefaultState();
	}


	function onClick(event){
		event.preventDefault();
		load();
	}

	function setEvents(){
		button.addEventListener('click', onClick);
	}

	function fireEvent() {
		var event = document.createEvent('Event');
		event.initEvent('ajaxModalLoad', true, true);
		document.dispatchEvent(event);
	};

	init();

	API.load = load;

	return API;
}

export default AjaxModal;

