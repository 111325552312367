import './js.cookie'

(function(){
	if (Cookies.get('COOKIES_ACCEPTED') === 'true') {return;}

	var cookiesBanner = document.querySelector('.js-cookies-banner');

	if (!cookiesBanner) {return;}

	setTimeout(function(){
		cookiesBanner.classList.remove('cookie-banner--hidden');
	}, 5000);

	var cookiesCloseBtn = cookiesBanner.querySelector('.js-cookies-accept');

	if (cookiesCloseBtn) {
		cookiesCloseBtn.addEventListener('click', acceptCookies);
	}

	function acceptCookies() {
		Cookies.set('COOKIES_ACCEPTED', 'true', { expires: 366 });
		cookiesCloseBtn.removeEventListener('click', acceptCookies);
		cookiesBanner.classList.add('cookie-banner--hidden');
	}
})();