var FileUploadManager = function (container, options) {
	var API = {},
		setting = {};

	if (!container) { return };

	function getOptions() {
		if (!options) { options = {} };

		setting.onChange = options.onChange || function(){};
		setting.onComplete = options.onComplete || function(){};
		setting.onRemove = options.onRemove || function(){};
	};

	function init() {
		getOptions();

		setting.files = [];
		setting.fileIndex = 0;

		setting.input = container.querySelector('[data-file-input]');
		setting.inputItem = container.querySelector('[data-input-item]');
		setting.isMultiple = setting.input.multiple;

		setting.fileList = container;

		var fileInputAttribute = container.getAttribute('data-file-loader');
		if (fileInputAttribute !== '') {
			var targetList = document.getElementById(fileInputAttribute);
			if (targetList) {
				setting.fileList = targetList
			}
		}

		setEvents();
	}

	function showButton() {
		setting.inputItem.classList.remove('is-hide');
	}
	function hideButton() {
		setting.inputItem.classList.add('is-hide');
	}

	function renderItems(files) {
		if(!setting.isMultiple){
			setting.fileList.innerHTML = '';
		}
		setting.fileList.prepend(createItems(files))
	}

	function createItems(files) {
		var fragment = document.createDocumentFragment();

		for (var i = 0; i < files.length; i++) {
			var item = document.createElement('div');

			item.className = 'file-list__item';
			item.innerHTML = createItem(files[i], setting.fileIndex);

			fragment.append(item);
		}

		return fragment;
	}

	function createItem(file) {
		return [
			'<div class="file-view">',
				'<div class="file-view__icon">',
					'<i class="icon icon--doc"></i>',
				'</div>',
				'<div class="file-view__body">',
					'<div class="file-view__title">' + file.name + '</div>',
				'</div>',
				'<a href="" class="file-view__remove link" data-file-remove="' + file.index + '">',
					'<i class="icon icon--close"></i>',
				'</a>',
			'</div>'
		].join('');
	}

	function removeItem(index) {
		var removeFile;
		for (var i = 0; i < setting.files.length; i++) {
			if (setting.files[i].index == index) {
				removeFile = setting.files[i];
				setting.files.splice(i, 1)
				return removeFile;
				break;
			}
		}

		if (setting.files.length == 0) {
			setting.input.value = '';
		}
	}

	function reset() {
		setting.files = [];
		setting.value = '';

		var items = setting.fileList.querySelectorAll('.files-loader__item');
		for (var i = 0; i < items.length; i++) {
			if (!items[i].hasAttribute('data-input-item')) {
				setting.fileList.removeChild(items[i]);
			}
		}

		setting.fileList.innerHTML = '';

		showButton();
	}

	function onChange() {
		for (var i = 0; i < this.files.length; i++) {
			this.files[i].index = setting.fileIndex;
			setting.fileIndex++;
			setting.files.push(this.files[i]);
		}

		renderItems(this.files);

		if (!setting.isMultiple) {
			hideButton();
		}

		if (setting.onChange) {
			var state = {};

			state.currentFiles = this.files;
			state.allFiles = setting.files;

			setting.onChange(state);
		}
	}

	function onRemove(event) {
		event.preventDefault();
		var removeFile;
		var fileIndex = $(this).attr('data-file-remove');

		$(this).closest('.file-list__item').remove();

		removeFile = removeItem(parseInt(fileIndex));
		showButton();

		if (setting.onRemove) {
			setting.onRemove(removeFile, setting.files.length);
		}
	}


	function setEvents() {
		setting.input.addEventListener('change', onChange);
		$(setting.fileList).on('click', '[data-file-remove]', onRemove);
	}

	function getFiles() {
		return setting.files;
	}

	init();

	API.getFiles = getFiles;
	API.reset = reset;

	container.API = API;
	return API;
}

export default FileUploadManager;
