/*
	filterType - тип фильтра. select или panel. panel - группа кнопок
*/
var FilterList = function(filter, filterType){
	var API = {},
		setting = {};

	function init(){
		setEvents();

		setting.listContainerSelector = filter.getAttribute('data-filter-list');
		setting.listContainer = document.getElementById(setting.listContainerSelector);

		setting.panelButtons = filter.querySelectorAll('[data-filter-tag]');

		if(!setting.listContainer){
			console.error('setting.listContainer is undefined');
			return;
		}

		setting.filterItems = setting.listContainer.querySelectorAll('[data-filter-item]');

		getFIlterModel();
	}

	function getFIlterModel(){
		setting.filterModel = [];
		for(var i = 0; i < setting.filterItems.length; i++){
			var modelItem = {};

			modelItem.node = setting.filterItems[i];
			modelItem.tags = setting.filterItems[i].getAttribute('data-filter-item').trim().replace(/\s/gi, '').split(',');

			setting.filterItems[i].classList.add('is-init');

			setting.filterModel.push(modelItem);
		}
	}

	function filterByTag(tag){
		if (tag == 'ALL') {
			showAll();
		}
		else {
			hideItems();
			showByTag(tag);
		}
	}

	function hideItems(){
		for (var i = 0; i < setting.filterModel.length; i++) {
			setting.filterModel[i].node.style.display = 'none';
		}
	}

	function showByTag(tag){
		for (var i = 0; i < setting.filterModel.length; i++) {
			if(setting.filterModel[i].tags.indexOf(tag) !== -1){
				setting.filterModel[i].node.style.display = 'block';
			}
		}
	}
	function showAll() {
		for (var i = 0; i < setting.filterModel.length; i++) {
			setting.filterModel[i].node.style.display = 'block';
		}
	}

	function clearPanelState() {
		for (var i = 0; i < setting.panelButtons.length; i++) {
			setting.panelButtons[i].classList.remove('is-active');
		}
	}


	function setEvents(){
		if(filterType == 'select'){
			filter.addEventListener('change', onChangeFilter);
		}
		else if(filterType == 'panel'){
			for(var i = 0; i < setting.panelButtons.length; i++){
				setting.panelButtons[i].addEventListener('click', onClickFilter);
			}
		}
	}

	function onChangeFilter(){
		var tag = this.value;
		filterByTag(tag);
	}

	function onClickFilter(event){
		event.preventDefault();

		var tag = this.getAttribute('data-filter-tag');

		filterByTag(tag);

		clearPanelState();
		this.classList.add('is-active');
	}

	init();

	return API;
}


export default FilterList;