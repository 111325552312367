import '../libs/swiper/swiper.min';

function init(){
	var containers = document.querySelectorAll('.js-album-slider');

	for (var i = 0; i < containers.length; i++) {
		initSlider(containers[i]);
	};
}

function initSlider(container){
	if(!container){return}
	var nav = {};

	nav.prev = container.querySelector('[data-slider-prev]');
	nav.next = container.querySelector('[data-slider-next]');
	nav.progress = container.querySelector('[data-slider-progress]');

	var slider = new Swiper(container, {
		speed: 600,
		slidePrevClass: 'is-prev',
		slideActiveClass: 'is-active',
		slideNextClass: 'is-next',
		slidesPerView: 2,
		spaceBetween: 7,
		watchOverflow: true,
		pagination: {
			el: nav.progress,
			type: 'progressbar',
			progressbarFillClass: 'progress__bar',
		},
		breakpoints: {
			640: {
				slidesPerView: 1,
			},
		},
		navigation: {
			nextEl: nav.next,
			prevEl: nav.prev,
			disabledClass: 'is-disable'
		},
	});
	container.swiperSlider = slider;
}

export default init;