(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
function ScrollSpy (wrapper, opt) {

  this.doc = document;
  this.wrapper = (typeof wrapper === 'string') ? this.doc.querySelector(wrapper) : wrapper;
  this.nav = this.wrapper.querySelectorAll(opt.nav);

  this.contents = [];
  this.win = window;

  this.winH = this.win.innerHeight;

  this.className = opt.className;

  this.offsetTop = opt.offsetTop;

  this.callback = opt.callback;

  this.init();

  this.scrollTop;
  this.scrollHeight;
}

ScrollSpy.prototype.init = function () {
  this.contents = this.getContents();
  this.attachEvent();

  if(window.smoothieScroll !== undefined){
    this.scrollHeight = smoothieScroll.state.get('height') + smoothieScroll.state.get('bounding');
  }
  else {
    this.scrollHeight = this.getScrollHeight();
  }

  this.getScrollTop();
};

ScrollSpy.prototype.getContents = function () {
  var targetList = [];

  for (var i = 0, max = this.nav.length; i < max; i++) {
    var href = this.nav[i].href;

    targetList.push(this.doc.getElementById(href.split('#')[1]));
  }

  return targetList;
};

ScrollSpy.prototype.getScrollTop = function () {
    if(window.smoothieScroll !== undefined){
      this.scrollTop = smoothieScroll.current;
    }
    else {
      this.scrollTop = this.doc.documentElement.scrollTop || this.doc.body.scrollTop;
    }

}
ScrollSpy.prototype.attachEvent = function () {
  this.win.addEventListener('load', (function () {
    this.spy(this.callback);
  }).bind(this));


  var scrollingTimer,
      _this = this;

  function onScrollHandler(){
    _this.getScrollTop();

    _this.spy(_this.callback);
  }

  //Обработчик для кастомномго скрола
  if(window.smoothieScroll !== undefined){
    window.smoothieScroll.addListener(throttle(onScrollHandler, 100));
  }

  this.win.addEventListener('scroll', throttle(onScrollHandler, 10));


  var resizingTimer;

  this.win.addEventListener('resize', (function () {
    if (resizingTimer) {
      clearTimeout(resizingTimer);
    }

    var _this = this;

    resizingTimer = setTimeout(function () {
      if(window.smoothieScroll !== undefined){
        _this.scrollHeight = smoothieScroll.state.get('height') + smoothieScroll.state.get('bounding');
      }
      else {
        _this.scrollHeight = _this.getScrollHeight();
      }
      _this.spy(_this.callback);
    }, 10);
  }).bind(this));
};

ScrollSpy.prototype.spy = function (cb) {
  var elems = this.getElemsViewState();

  this.markNav(elems);
  this.checkLastItem();

  if (typeof cb === 'function') {
    cb(elems);
  }
};

ScrollSpy.prototype.getElemsViewState = function () {
  var elemsInView = [],
    elemsOutView = [],
    viewStatusList = [];

  for (var i = 0, max = this.contents.length; i < max; i++) {
    var currentContent = this.contents[i],
      isInView = this.isInView(currentContent);
    if (isInView) {
      elemsInView.push(currentContent);
    } else {
      elemsOutView.push(currentContent);
    }
    viewStatusList.push(isInView);
  }

  return {
    inView: elemsInView,
    outView: elemsOutView,
    viewStatusList: viewStatusList
  };
};

ScrollSpy.prototype.isInView = function (el) {
  var winH = this.winH,
    offsetTop,
    // scrollTop,
    scrollBottom,
    rect,
    elTop,
    elBottom;


  scrollBottom = this.scrollTop + winH;
  rect = el.getBoundingClientRect();
  elTop = rect.top + this.scrollTop;
  elBottom = elTop + el.offsetHeight;

  if(this.offsetTop !== undefined){
    offsetTop = this.offsetTop;
  }
  else {
    offsetTop = 0;
  }
  return (elTop < scrollBottom) && (elBottom >= this.scrollTop + offsetTop);
};

ScrollSpy.prototype.markNav = function (elems) {
  var navItems = this.nav,
    isAlreadyMarked = false;

  for (var i = 0, max = navItems.length; i < max; i++) {
    if (elems.viewStatusList[i] && !isAlreadyMarked) {
      isAlreadyMarked = true;
      navItems[i].classList.add(this.className);
    } else {
      navItems[i].classList.remove(this.className);
    }
  }
};

ScrollSpy.prototype.checkLastItem = function(){
  if(this.scrollTop + window.innerHeight + 2 >= this.scrollHeight){
    var navItems = this.nav;
    for (var i = 0, max = navItems.length; i < max; i++) {
      navItems[i].classList.remove(this.className);
    }
    navItems[navItems.length - 1].classList.add(this.className);
  }
}

ScrollSpy.prototype.getScrollHeight = function(){
  var scrollHeight = Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
  return scrollHeight;
}



module.exports = ScrollSpy;

},{}],2:[function(require,module,exports){
(function (global){
/**
 * ScrollSpy
 *
 */

var ScrollSpy = require('./modules/scrollspy');

global.ScrollSpy = module.exports = ScrollSpy;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./modules/scrollspy":1}]},{},[2]);
