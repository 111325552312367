var ThumbsSlider = function(container, startIndex){
	var API = {},
		setting = {};

	function init(){
		setting.startIndex = startIndex;
		setting.srcs = getThumbsSrc();

		setting.viewCount = 2;
		setting.slidesCount = setting.srcs.length;
		setting.maxPosition = setting.slidesCount - setting.viewCount - 1;

		setting.step = 100 / setting.viewCount;

		setTemplate();
		setThumbsSlides();

		setPosition(setting.startIndex);
	}

	function getThumbsSrc(){
		return container.getAttribute('data-slider-thumbs').trim().replace(/\t/ig, '').replace(/\n/ig, '').split(',');
	}

	function setTemplate(){
		var template = [
			'<div class="thumbs-slider js-thumbs-slider">',
				'<div class="thumbs-slider__inner js-thumbs-slider-tape">',
				'</div>',
			'</div>',
		].join('');

		container.innerHTML = template;

		setting.thumbsSlider = container.querySelector('.js-thumbs-slider');
		setting.thumbsSliderTape = container.querySelector('.js-thumbs-slider-tape');
	}

	function setThumbsSlides(){
		var slides = '';

		for(var i = 0; i < setting.srcs.length; i++){
			if(setting.srcs[i] !== ""){
				slides += '<div class="thumbs-slider__slide"><div class="thumbs-slider__img img" style="background-image: url(' + setting.srcs[i] + ')"></div></div>'
			}

		}

		setting.thumbsSliderTape.innerHTML = slides;
	}

	function setPosition(position){
		setting.thumbsSliderTape.style.transform = 'translate3d(' + getNewPosition(position) + '%, 0, 0)';
	}

	function getNewPosition(position){
		var tmpPosition = position + 1;

		if(tmpPosition > setting.maxPosition){
			tmpPosition = setting.maxPosition;
		}

		return -setting.step * tmpPosition;
	}

	init();

	API.setPosition = setPosition;
	return API;
}

export default ThumbsSlider;