import $ from 'jquery';
import './CheckForm';

/*
	form - селектор формы - обязательный параметр
	requestFunction - функция отправки данных формы - обязательный параметр
	option - доп настройки
*/
var FormSubmiter = function (form, requestFunction) {
	var API = {},
		setting = {};

	if (form == undefined) {
		console.error('form undefined');
		return
	}

	function init() {
		setting.isSubmit = false;
		setting.isAjax = true;
		form.checkForm();

		setting.submitButton = form.find('[data-submit]');
		setting.messageElement = form.find('[data-message]');
		setting.messageText = form.find('[data-message-text]');

		if(setting.messageText.length == 0){
			setting.messageText = setting.messageElement;
		}

		setting.url = getFormSubmitUrl();

		checkRequestFunction();

		setEvent();
	}

	function checkRequestFunction() {
		if (requestFunction == undefined) {
			setting.isAjax = false;
		}
	}

	function getFormSubmitUrl() {
		if (form[0].hasAttribute('action') && form[0].getAttribute('action') !== '') {
			return form[0].getAttribute('action');
		}
		else if (form[0].hasAttribute('data-action') && form[0].getAttribute('data-action') !== '') {
			return form[0].getAttribute('data-action');
		}
		return null;
	}

	function onSubmit(event) {
		if (setting.isSubmit) { return }

		if (setting.submitButton.hasClass('is-disable')) {
			event.preventDefault();
			unValidateForm();
		}
		else {
			if (setting.isAjax) {
				event.preventDefault();
				prepareRequest();
			}
		}
	}

	function unValidateForm() {
		form.checkForm('validate');

		form.addClass('is-error');
		form.removeClass('is-success');

		setting.submitButton.addClass('is-animate');

		setTimeout(function () {
			setting.submitButton.removeClass('is-animate');
		}, 400)
	};

	function prepareRequest() {
		var requestOptions = {};
		setting.isSubmit = true;

		requestOptions.form = form;
		requestOptions.data = new FormData(form[0]);
		// requestOptions.data = form.serializeArray();
		requestOptions.url = setting.url;

		requestOptions.onSuccess = onSuccess;
		requestOptions.onError = onError;

		form.addClass('is-overlay');
		form.removeClass('is-error');
		setting.submitButton.addClass('is-overlay');

		requestFunction(requestOptions)
	}

	function clearForm() {
		form[0].reset();
		form.removeClass('is-overlay');

		form.find('.is-filled').removeClass('is-filled');
		form.checkForm();

		form.find('.is-error').removeClass('is-error');

		if(form.find('[data-file-loader]')[0]){
			form.find('[data-file-loader]')[0].API.reset();
		}

		setting.submitButton.removeClass('is-overlay');
		setting.submitButton.addClass('is-disable');
	}

	function onSuccess(response) {
		clearForm();
		setting.isSubmit = false;

		if (setting.messageElement !== undefined) {
			setting.messageText.html(response.data.message);

			setting.messageElement.show();
			setting.messageElement.addClass('is-success');
			form.addClass('is-message');

			setTimeout(function () {
				setting.messageElement.hide();
				setting.messageElement.removeClass('is-success');
				form.removeClass('is-message');
			}, 2500)
		}

		if (response.data && response.data.redirect) {
			window.location.replace(response.data.url);
		}
	}

	function onError(response) {
		var message;

		message = response.message;
		setting.isSubmit = false;

		if (message == undefined) {
			message = 'Что-то пошло не так';
		}

		form.removeClass('is-overlay');
		form.classList.add('is-message');

		setting.submitButton.removeClass('is-overlay');
		setting.submitButton.addClass('is-animate');

		if (setting.messageElement !== undefined) {
			setting.messageText.html(message);

			setting.messageElement.addClass('is-error');
			setting.messageElement.show();

			setTimeout(function () {
				setting.submitButton.removeClass('is-animate');
				setting.submitButton.removeClass('is-overlay');
				setting.messageElement.hide();
				setting.messageElement.removeClass('is-error');
				form.classList.remove('is-message');
			}, 2500)
		}

	}

	function onCloseMessage(event) {
		event.preventDefault();
		clearForm();
		setting.messageElement.hide();
	}

	function setEvent() {
		form.on('submit', onSubmit);
	}

	init();

	return API;
}

export default FormSubmiter;
