var offset_top = 0,
	speed = 400;

$('body').on('click', '[data-scroll-to]', function(event){
	var topPosition;
	var target;
	target = $(this).attr('href');
	if (target == '#'){
		topPosition = 0;
	}
	else {
		if(window.smoothieScroll !== undefined){
			topPosition = $(target).offset().top - $('#scrollContainer').offset().top;
		}
		else {
			topPosition = $(target).offset().top;
		}
	}

	// if($('.page__header').length != 0 & $('.page__header').css('position') == 'fixed'){
	// 	topPosition -= $('.page__header').outerHeight();
	// 	if(topPosition < 0){topPosition = 0;}
	// }

	if(window.smoothieScroll !== undefined){
		smoothieScroll.scrollTo(topPosition);
	}
	else {
		$('html, body').animate({scrollTop: topPosition - $('.page__header').outerHeight()}, 400);
	}
	return false;
});
