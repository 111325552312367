import '../libs/swiper/swiper.min';
import ProgressBar from './ProgressBar';
import ThumbsSlider from './ThumbsSlider';
import {initSliderState, changeSlide} from './swiper-helpers';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from '../utils/windowDimensions';

function init(){
	var containers = document.querySelectorAll('.js-animate-slider');

	for (var i = 0; i < containers.length; i++) {
		initSlider(containers[i]);
	};
}

function initSlider(container){
	if(!container){return}

	var nav = {},
		slider,
		animationTime = 680,
		isDesctopOnly = false,
		isMobileVirtual = true,
		breakpoints960 = {};

	breakpoints960 = {
		allowTouchMove: true,
		noSwiping: false,
		virtualTranslate: true,
		spaceBetween: 20,
	}

	if(container.hasAttribute('data-desctop-only')){
		isDesctopOnly = true,
		breakpoints960 = {
			allowTouchMove: true,
			noSwiping: false,
			virtualTranslate: false,
			simulateTouch: true,
			spaceBetween: 20,
		}
	}

	nav.prev = container.querySelector('[data-slider-prev]');
	nav.next = container.querySelector('[data-slider-next]');
	nav.progress = container.querySelector('[data-slider-progress]');
	nav.thumbs = container.querySelector('[data-slider-thumbs]');

	slider = new Swiper(container, {
		speed: animationTime,
		simulateTouch: false,
		noSwiping: true,
		slidePrevClass: 'is-prev',
		slideActiveClass: 'is-active',
		slideNextClass: 'is-next',
		virtualTranslate: true,
		slidesPerView: 1,
		watchOverflow: true,
		breakpoints: {
			959: breakpoints960
		},
		navigation: {
			nextEl: nav.next,
			prevEl: nav.prev,
			disabledClass: 'is-disable'
		},
		on: {
			init: function () {
				initSliderState(this);
				if(nav.progress){
					this.progressBar =  new ProgressBar(nav.progress, this.realIndex, this.slides.length);
				}
				if(nav.thumbs){
					this.thumbsSlider =  new ThumbsSlider(nav.thumbs, this.realIndex);
				}
			},
			slideChange: function(){
				if(this.progressBar){
					this.progressBar.setPosition(this.realIndex);
				}
				if(this.thumbsSlider){
					this.thumbsSlider.setPosition(this.realIndex);
				}
			},
			slidePrevTransitionStart: function () {
				if(isDesctopOnly && WINDOW_WIDTH < 960){return}
				changeSlide(this, animationTime, 'prev');
			},
			slideNextTransitionStart: function () {
				if(isDesctopOnly && WINDOW_WIDTH < 960){return}
				changeSlide(this, animationTime, 'next');
			},
		}
	});

	container.swiperSlider = slider;
	container.updateState = updateState;

}

function updateState(swiper){
	if(swiper.isStateInit){return}
	initSliderState(swiper);

	if(swiper.progressBar){
		swiper.progressBar.update(swiper.realIndex, swiper.slides.length);
	}
}


export default init;