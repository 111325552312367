function initSliderState(swiper) {
	for (var i = 0; i < swiper.slides.length; i++) {
		swiper.slides[i].style.transform = 'translate(-' + i * 100 + '%, 0)';
	}

	if(!swiper.slides[swiper.realIndex]){return}

	swiper.slides[swiper.realIndex].classList.add('is-show');

	swiper.isStateInit = true;
}

function changeSlide(swiper, time, direction) {
	if (swiper.isChange) { return }
	swiper.isChange = true;

	var enterClass = 'is-in-' + direction,
		leaveClass = 'is-out-' + direction;

	swiper.navigation.nextEl.style.pointerEvents = 'none';
	swiper.navigation.prevEl.style.pointerEvents = 'none';

	swiper.allowSlideNext = false;
	swiper.allowSlidePrev = false;
	swiper.allowTouchMove = false;

	swiper.slides[swiper.previousIndex].classList.add(leaveClass);
	swiper.slides[swiper.realIndex].classList.add(enterClass);
	swiper.slides[swiper.realIndex].classList.add('is-show');

	setTimeout(function () {
		swiper.allowSlideNext = true;
		swiper.allowSlidePrev = true;
		swiper.allowTouchMove = true;

		swiper.slides[swiper.previousIndex].classList.remove('is-show');
		swiper.slides[swiper.previousIndex].classList.remove(leaveClass);
		swiper.slides[swiper.realIndex].classList.remove(enterClass);

		swiper.navigation.nextEl.style.pointerEvents = '';
		swiper.navigation.prevEl.style.pointerEvents = '';

		swiper.isChange = false;
		setTimeout(function () {
			swiper.navigation.update();
		}, 25);
	}, time);
}

function onClick(event){
	event.stopPropagation();
	return false;
}

export {initSliderState, changeSlide}