import {WINDOW_HEIGHT, WINDOW_WIDTH} from './utils/windowDimensions';

function initBackButton(){
	var backButton = document.getElementById('backButton');

	if(!backButton){return}

	var topPosition = window.WINDOW_HEIGHT * 1.2;

	onScroll();
	window.addEventListener('scroll', throttle(onScroll, 100));

	function onScroll(){
		if(window.scrollY > topPosition){
			backButton.style.display = 'block';
		}
		else {
			backButton.style.display = 'none';
		}
	}
}


export default initBackButton;