/* global USE_SERVICE_WORKER */
import './webpack-imports';
import './sentry';
import './polyfills';

import './utils/throttle';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from './utils/windowDimensions';

import $ from 'jquery';
import IMask from 'imask';
import 'jquery-mask-plugin';
import './libs/scrollSpy';
import './libs/wow';
import './libs/CookieBanner';

import requestAjax from './libs/requestAjax';
import FormSubmiter from './libs/FormSubmiter';
import FileUploadManager from './libs/FileUploadManager';

import 'bootstrap-select';

import StickyAside from './libs/StickyAside';
import initBackButton from './libs/BackButton';
import FilterList from './libs/FilterList';
import {SliderWatcher, resizeStateWatcher} from './libs/ResizeStateWatcher';
import Spoiler from './libs/Spoiler';

import initBaseSlider from './sliders/baseSlider';
import initAnimateSlider from './sliders/animateSlider';
import initAlbumSlider from './sliders/albumSlider';

import AjaxModal from './libs/ajaxModal';

import 'lightgallery.js';
import 'lightgallery.js/dist/css/lightgallery.css';



window.$ = $;
window.jQuery = $;

require('./libs/bootstrap.snippets');
require('./libs/scrollTo');

initBaseSlider();
initAnimateSlider();
initAlbumSlider();

initBackButton();


new WOW({
	offset: WINDOW_HEIGHT * 0.1
}).init();

window.setLoadedState();

(function(){
	function init(){
		checkFixedTheme(window.pageYOffset);
		window.addEventListener('scroll', throttle(onScroll, 120));
	}

	function onScroll(){
		var scrollTop = window.pageYOffset;
		checkFixedTheme(scrollTop);
	}

	var pageHeader = document.querySelector('.page__header'),
		isHeaderStateChange = false;

	function checkFixedTheme(scrollTop){
		if(scrollTop > 0){
			if(isHeaderStateChange){return}
			isHeaderStateChange = true;
			pageHeader.classList.add('is-fill');
		}
		else {
			if(!isHeaderStateChange){return}
			isHeaderStateChange = false;
			pageHeader.classList.remove('is-fill');
		}
	}

	init();
})();


$('.selectpicker').selectpicker({
	selectOnTab: true,
});
document.addEventListener('ajaxModalLoad', function(){
	$('.selectpicker').selectpicker({
		selectOnTab: true,
	});
});


// StickyAside Init
(function(){
	var stickyAsideContainer = document.querySelector('.js-sticky-aside');
	if(!stickyAsideContainer) return;
	var stickyAside = new StickyAside(stickyAsideContainer);

	var stickyWatcher = new SliderWatcher(stickyAside.update, stickyAside.destroy, false, 960);
	resizeStateWatcher.add(stickyWatcher);
})();


(function(){
	$('[data-toggle="tab"]').on('shown.bs.tab', function(e) {
		var targetSelector = event.target.getAttribute('href'),
			scrollTop = window.pageYOffset;

		var target = document.querySelector(targetSelector);
		if (!target) {return}

		var slider = target.querySelector('[data-tab-slider]');
		if(!slider){return}

		if (slider.swiperSlider) {
			window.scrollTo(0, scrollTop + 1);
			slider.swiperSlider.update();
			if(slider.updateState){
				slider.updateState(slider.swiperSlider);
			}
		}
	});
})();


//Инит аяксовых модалок
(function(){
	function onAjaxModalRun(event){
		event.preventDefault();

		if(this.isAjaxModal){return}
		this.isAjaxModal = true;

		new AjaxModal(this, {
			onLoad: function(modalContainer){
				var slider = modalContainer.querySelector('[data-modal-slider]');
				if(!slider){return}
				setTimeout(function(){
					if (slider.swiperSlider) {
						slider.swiperSlider.update();
						if(slider.updateState){
							slider.updateState(slider.swiperSlider);
						}
					}
					else {
						initAlbumSlider();
					}
				}, 50);
			}
		}).load();
	}

	$(document).on('click', '[data-ajax-modal]', onAjaxModalRun);
})();


function touchMoveLock(event){
	event.preventDefault();
}
function lockHtml() {
	var scrollWidth = WINDOW_WIDTH - $('html').width();

	$('html').css('margin-right', scrollWidth + 'px');
	$('.page__header').css('margin-right', scrollWidth + 'px');
	$('html').addClass('is-lock');

	document.addEventListener('ontouchmove', touchMoveLock);
}
function unLockHtml() {
	$('html').css('margin-right', 0);
	$('.page__header').css('margin-right', 0);
	$('html').removeClass('is-lock');

	document.removeEventListener('ontouchmove', touchMoveLock);
}


(function(){
	var modalAnimationTime = 0;
	$('body').on('click', '[data-drop-toggle]', function (event) {
		event.preventDefault();
		toggleDrop(this);
	});

	function closeModals() {
		$('[data-drop-toggle]').each(function () {
			var targetSelector = $(this).removeClass('is-open').attr('data-drop-toggle'),
				target = $('#' + targetSelector);

			if (target.hasClass('is-open')) {
				target.addClass('is-out');

				setTimeout(function (item) {
					return function () {
						item.removeClass('is-out is-open');
						unLockHtml();
					}
				}(target), modalAnimationTime);
			}
		});
	}

	$(document).on('keyup', function (event) {
		if (event.keyCode == 27) {
			closeModals();
		}
	});

	window.closeModals = closeModals;

	function toggleDrop(_this) {
		var $this = $(_this),
			target = $this.attr('data-drop-toggle'),
			$target = $('#' + target);

		if (!$target.hasClass('is-open')) {
			closeModals();
			$('[data-drop-toggle="' + target + '"]').toggleClass('is-open');
			if (!$this[0].hasAttribute('data-scroll-free')) {
				lockHtml();
			}


			var modalForm = $target.find('form');
			if(modalForm.checkForm){
				modalForm.checkForm('update');
			}

			var slider = $target[0].querySelector('[data-modal-slider]');
			if(slider){
				setTimeout(function(){
					if (slider.swiperSlider) {
						slider.swiperSlider.update();
						if(slider.updateState){
							slider.updateState(slider.swiperSlider);
						}
						if(_this.hasAttribute('data-set-slide')){
							slider.swiperSlider.slideTo(_this.getAttribute('data-set-slide'));
						}
					}
				}, 100);
			}
			fireEvent();
		}
		if ($target.hasClass('is-open')) {
			$target.addClass('is-out');

			$('[data-drop-toggle="' + target + '"]').removeClass('is-open');

			setTimeout(function (item) {
				return function () {
					unLockHtml();
					item.removeClass('is-out is-open');
				}
			}($target), modalAnimationTime);
		}
		else {
			$target.toggleClass('is-open');
		}
	};

	function showModal(modalId, options) {
		var modal = $('#' + modalId);
		modal.addClass('is-open');

		lockHtml();
		fireEvent();
	}

	function fireEvent() {
		var event = document.createEvent('Event');
		event.initEvent('modalOpen', true, true);
		document.dispatchEvent(event);
	};
	window.showModal = showModal;
})();


//Смена цвета боковой колонки и кнопки наверх при пересечении с секциями
(function(){
	var asideContainer = document.getElementById('pageNav'),
		backButton = document.getElementById('backButton');

	if(!asideContainer){return}

	var containers = document.querySelectorAll('.page__section');

	var translateObserver = new IntersectionObserver(function(entries){
		for (var i = 0; i < entries.length; i++) {
			if (entries[i].isIntersecting){

				if(entries[i].target.hasAttribute('data-theme')){
					asideContainer.classList.add('is-light');
					backButton.classList.add('is-light');
				}
				else {
					asideContainer.classList.remove('is-light');
					backButton.classList.remove('is-light');
				}
			}
		}
	}, {
		threshold: [0, 1],
		rootMargin: '-60% 0% -40% 0%',
	});

	for(var i = 0; i < containers.length; i++){
		translateObserver.observe(containers[i]);
	}
})();


//Смена цвета боковой колонки и кнопки наверх при пересечении с секциями
(function(){
	var fixedBgSection = document.querySelectorAll('[data-fixed-bg]');

	if(!fixedBgSection.length){return}

	var translateObserver = new IntersectionObserver(function(entries){
		for (var i = 0; i < entries.length; i++) {
			entries[i].target.classList.remove('is-viewed');
			if (entries[i].isIntersecting){
				entries[i].target.classList.add('is-viewed');
			}
		}
	}, {
		threshold: [0, 1],
		// rootMargin: '-60% 0% -60% 0%',
	});

	for(var i = 0; i < fixedBgSection.length; i++){
		translateObserver.observe(fixedBgSection[i]);
	}
})();

(function(){
	function onAjaxModalRun(event){
		event.preventDefault();

		if(this.isAjaxModal){return}
		this.isAjaxModal = true;

		new AjaxModal(this, {}).load();
	}

	$(document).on('click', '[data-ajax-modal-default]', onAjaxModalRun);
})();


(function(){
	function onClick(event){
		event.preventDefault();

		var link = this,
			url = link.getAttribute('href'),
			listContainerSelector = link.getAttribute('data-load-list'),
			listContainer = document.getElementById(listContainerSelector),
			listContainerMoreItem = listContainer.querySelector('[data-list-more]'),
			options = {};

		if(link.isLoad){return}
		link.isLoad = true;

		options.url = url;

		options.onSuccess = function(response){
			listContainer.insertAdjacentHTML('beforeEnd', response.data.html);
			listContainerMoreItem.remove();
			link.isLoad = false;
		};
		options.onError = function(response){
			link.isLoad = false;
		};

		requestAjax(options);
	}

	$(document).on('click', '[data-load-list]', onClick);

})();

(function(){
	// Маска для телефона
	 $('[type="tel"]').mask('Z0#', {
		 translation: {
			 'Z': {
				 pattern: /[\+]/,
				 optional: true,
			 }
		 }
	 });
})();


// ScrollSpy Init
(function(){
	if(!document.querySelector('.js-scrollspy-nav')){return}
	var spy = new ScrollSpy('body', {
		nav: '.js-scrollspy-nav > .page-nav__item > .page-nav__link',
		className: 'is-active',
		offsetTop: $('.page__header').outerHeight() + 10,
	});
})();


//List filters init
(function(){
	function initFilters(){
		var filters = document.querySelectorAll('[data-filter-select]');

		for(var i = 0; i < filters.length; i++){
			if(filters[i].isInit){continue}
			filters[i].isInit = true;

			var filter = new FilterList(filters[i], 'select');
		}
	}
	initFilters();
	document.addEventListener('ajaxModalLoad', function(){
		initFilters();
	});
})();


//Form submit init
(function(){
	var ajaxForms = document.querySelectorAll('.js-ajax-form');

	for(var i = 0; i < ajaxForms.length; i++){
		var formSubmiter = new FormSubmiter($(ajaxForms[i]), requestAjax);
	}

	var defaultForms = document.querySelectorAll('.js-ajax');
	for(var i = 0; i < ajaxForms.length; i++){
		var formSubmiter = new FormSubmiter($(ajaxForms[i]));
	}

    var phoneInput = Array.from(document.querySelectorAll<HTMLElement>('[type="tel"]'));
    var maskOptions = {
    mask: '+{7}(000)000-00-00'
    };

    phoneInput.forEach(phone => {
        var mask = IMask(phone, maskOptions);
    })
})();


(function(){
	function multiInitSpoiler(){
		var toggleCtrls = document.querySelectorAll('[data-toggle="spoiler"]');
		for (var i = 0; i < toggleCtrls.length; i++) {
			var spoiler = new Spoiler(toggleCtrls[i]);
		}
	}
	multiInitSpoiler();
})();


// File upload init
(function(){
	var items = document.querySelectorAll('[data-file-loader]');

	for (var i = 0; i < items.length; i++) {
		var fileManager = new FileUploadManager(items[i], {
			onChange: function (state) {},
			onRemove: function (removeFile) {},
		});
	}
})();
// изображения в карточке товаров

declare var lightGallery: any;

(function(){
	const mainPhoto = document.querySelectorAll('.js-card-slide');
    const smallPhoto = document.querySelectorAll('.js-card-ctrl-slide');

    function activePhoto() {
        if (smallPhoto.length > 0 && mainPhoto.length > 0) {
            smallPhoto[0].classList.add('is-active');
            mainPhoto[0].classList.add('is-active');
            mainPhoto[0].classList.add('is-show');

            smallPhoto.forEach((item, index) => {
                item.addEventListener('click', () => {
                    changeClassActivePhoto(index);
                })
            });
        }
    }

    function changeClassActivePhoto(index: any) {
        smallPhoto.forEach(item => {
            item.classList.remove('is-active');
        });
        smallPhoto[index].classList.add('is-active');

        mainPhoto.forEach((item, i) => {
            item.classList.remove('is-active');
            item.classList.remove('is-show');
        });

        mainPhoto[index].classList.add('is-active');
        mainPhoto[index].classList.add('is-show');
    }

    if (smallPhoto && mainPhoto) {
        activePhoto();
    }

    let lg = document.getElementById('lightGallery');

    if (lg && window.matchMedia('(min-width: 960px)').matches) {
        lg.addEventListener('onBeforeSlide', function(event){
            const index = event.detail.index;

            changeClassActivePhoto(index);
        });

        lightGallery(lg, {
            mode: 'lg-slide',
        });
    }

    if (window.matchMedia('(max-width: 959px)').matches) {

        mainPhoto.forEach(photo => {
            photo.addEventListener('click', (e) => {
                e.preventDefault();
            })
        });
    }

})();

// табы на карточке товара

(function(){
	const tabs = document.querySelectorAll('[data-card-tab]');
	const tabContent = document.querySelectorAll('[data-tab-content]');

    function tabClick() {
        tabs.forEach(item => {
            item.addEventListener('click', () => {
                const attributeTab = item.getAttribute('data-card-tab');
                tabs.forEach(item => item.classList.remove('is-active'));
                item.classList.add('is-active');

                tabContent.forEach(content => {
                    content.classList.remove('is-active');
                    const attributeContent = content.getAttribute('data-tab-content');
                    if (attributeContent === attributeTab) {
                        content.classList.add('is-active');
                    }
                });

            });
        });
    }

    if (tabs && tabContent) {
        tabClick();
    }
})();

// купон

(function(){
	const inputCoupon = document.querySelector<HTMLInputElement>('[data-coupon-input]');
	const urlCoupon: any = inputCoupon?.getAttribute('data-url');
	const couponBtn: HTMLElement | null = document.querySelector('[data-coupon-button]');
	const couponCancel = document.querySelector('[data-coupon-cancel]');
	const priceBlock = document.querySelector<HTMLElement>('.js-price-block');
	const errorInner = document.querySelector<HTMLElement>('.js-coupon-error');
    let coupunArr = {
        event_id: 1,
        coupon: ''
    };
    let oldPrice;

    if (inputCoupon && couponBtn) {
        couponBtn.addEventListener('click', () => {
            if (inputCoupon.value.length !== 0) {
                couponBtn.classList.add('is-overlay');

                coupunArr.event_id = Number(inputCoupon.getAttribute('data-id-coupon'));
                coupunArr.coupon = inputCoupon.value;
                getNewPrice();
            }
        })
    }

    if (inputCoupon) {
        inputCoupon.addEventListener('input', () => {
            if (inputCoupon.classList.contains('is-error')) {
                if (errorInner) errorInner.innerHTML = '';
                inputCoupon.classList.remove('is-error');
                if (couponBtn) couponBtn.style.display = "block";
            }
        })
    }

    if (priceBlock && couponCancel) {
        couponCancel.addEventListener('click', () => {
            if (oldPrice) {
                priceBlock.innerHTML = `
                    <div class="price-card__count">${oldPrice}<span>₽</span></div>
                `;
            }
            couponCancel.classList.remove('is-show');
            if (couponBtn) couponBtn.style.display = "block";
        })
    }

    // запрос на сервер
    const getNewPrice = async () => {
        const res = await fetch(urlCoupon, {
            method: 'POST',
            body: JSON.stringify(coupunArr)
        })
        .then(response => response.json())
        .then(data => {
            if (errorInner) errorInner.innerHTML = '';
            inputCoupon?.classList.remove('is-error');

            if (data.success) {
                if (priceBlock) {
                    priceBlock.innerHTML = `
                        <div class="price-card__count price-card__count--old">${data.data.old_price}<span>₽</span></div>
                        <div class="price-card__count">${data.data.price}<span>₽</span></div>
                    `;
                    oldPrice = data.data.old_price;

                    setTimeout(() => {
                        if (couponBtn) {
                            couponBtn.classList.remove('is-overlay');
                            couponBtn.style.display = "none";
                            if (couponCancel) couponCancel.classList.add('is-show');
                        }
                    }, 300);
                }
            } else {
                if (couponBtn) {
                    couponBtn.classList.remove('is-overlay');
                    couponBtn.style.display = "none";
                }
                if (errorInner) errorInner.innerHTML = data.message;
                inputCoupon?.classList.add('is-error');
            }
        })
        .catch((response) => {
        })
        .finally(() => {
        });
    };
})();

(function() {
    var url = document.location.href;
    var navLinks = document.querySelectorAll<HTMLElement | any>('.js-nav-link');
    navLinks.forEach(link => {
        if (link.href === url) {
            link.classList.add('is-active');
        } else {
            link.classList.remove('is-active');
        }
    });
})();

(function() {
    const videoBlock = document.querySelectorAll<HTMLElement>('.js-card-video-block');

    if (videoBlock.length > 0) {
        videoBlock.forEach(item => {
            item.addEventListener('click', () => {
                const previewPhoto = item.querySelector<HTMLElement>('.js-card-video-previw');
                const video = item.querySelector<HTMLElement | any>('.js-card-video');

                if (previewPhoto && video) {
                    previewPhoto.style.display="none";
                    let videoURL = video.getAttribute('src');
                    videoURL += "?autoplay=1";
                    video.src = videoURL;
                }

            })
        })
    }
})();

(function(){
	$('.js-cert-list-toggle-button').click(function(e) {
		e.preventDefault();
		$('.js-cert-list-toggle').toggle(300);
		$('.js-cert-list-toggle-text').toggle(300);
		$('.js-cert-list-toggle-block').toggleClass('force-hover');
	});
})();
