var StickyAside = function(container){
	var API = {},
		setting = {};

	function init(){
		setting.footerContainer = document.querySelector('.page__footer');
		getParams();
		setEvents();
		checkAsideState();
	}

	function update(){
		setting.footerContainer = document.querySelector('.page__footer');
		getParams();
		setEvents();
		checkAsideState();
	}

	function destroy(){
		window.removeEventListener('scroll', onScroll);
		window.removeEventListener('resize', throttle);

		container.style.position = '';
		container.style.top = '';
	}

	function getParams(){
		var boundRect = container.getBoundingClientRect();

		setting.scrollTop = window.pageYOffset;

		if(!setting.footerContainer){
			setting.endPosition = setting.scrollTop + WINDOW_HEIGHT;
		}
		else {
			setting.endPosition = setting.footerContainer.getBoundingClientRect().top + setting.scrollTop;
		}

		setting.topPosition = boundRect.top;
		setting.containerHeight = boundRect.height;
		setting.bottomPosition = setting.topPosition + setting.containerHeight;
	}

	function onScroll(){
		setting.scrollTop = window.pageYOffset;
		checkAsideState();
	}

	function checkAsideState(){
		if(setting.bottomPosition + setting.scrollTop >= setting.endPosition){
			if(setting.isFixed){return}
			setting.isFixed = true;
			fixAside();
		}
		else {
			if(!setting.isFixed){return}
			setting.isFixed = false;
			releaseAside();
		}
	}

	function fixAside() {
		container.style.position = 'absolute';
		var tmpTopPosition = container.getBoundingClientRect().top + setting.scrollTop;
		container.style.top = (setting.endPosition - setting.containerHeight - tmpTopPosition) + 'px';
	}
	function releaseAside(){
		container.style.position = 'fixed';
		container.style.top = 0;
	}

	function onResize(){
		getParams();
		checkAsideState();
	}

	function setEvents(){
		window.addEventListener('scroll', onScroll);
		window.addEventListener('resize', throttle(onResize, 150));
	}

	init();

	API.update = update;
	API.destroy = destroy;

	return API;
}


export default StickyAside;