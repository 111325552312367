function throttle(func, ms) {
  var isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {

    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};

var Spoiler = function(element, onToggleEvent){
	var API = {},
		setting = {},
		_throttle = throttle;

	setting.onToggleEvent = onToggleEvent ? onToggleEvent : null;

	function init(){
		getParams();
		checkToggleCtrlState();
		initSpoilerBody();
		getRelativeElements();
		setEvent();
	}

	function update(){
		setting.spoilerBody.classList.remove('is-init');
		setting.spoilerBody.style.height = 'auto';

		getParams();
		checkToggleCtrlState();
		initSpoilerBody();

		setting.spoilerBody.classList.add('is-init');
	}

	function getParams(){
		setting.isCollapse = true;

		setting.targetSelector = element.getAttribute('href');
		setting.spoilerBody = document.querySelector(setting.targetSelector);
		setting.spoilerBodyInner = setting.spoilerBody.children[0];

		setting.spoilerBodyHeight = setting.spoilerBody.getBoundingClientRect().height;
		setting.spoilerBodyInnerHeight = setting.spoilerBodyInner.getBoundingClientRect().height;
	}

	function getRelativeElements(){
		var relativeElements = document.querySelectorAll('[data-toggle-relative="spoiler"]');
		setting.relativeElements = [];

		for (var i = 0; i < relativeElements.length; i++) {
			if(relativeElements[i].getAttribute('href') == setting.targetSelector){
				setting.relativeElements.push(relativeElements[i]);
			}
		}
	}

	function initSpoilerBody(){
		setting.spoilerBody.style.height = setting.spoilerBodyHeight + 'px';
		setting.spoilerBody.classList.add('is-init');
	}

	function checkToggleCtrlState(){
		if(setting.spoilerBodyHeight >= setting.spoilerBodyInnerHeight){
			hideToggleCtrl();
		}
		else {
			showToggleCtrl();
		}
	}

	function showToggleCtrl(){
		element.style.display = '';
	}
	function hideToggleCtrl(){
		element.style.display = 'none';
	}

	function showSpoiler(){
		element.classList.add('is-open');
		setting.spoilerBody.style.height = setting.spoilerBodyInnerHeight + 'px';
		setting.spoilerBody.classList.add('is-open');
	}
	function hideSpoiler(){
		element.classList.remove('is-open');
		setting.spoilerBody.style.height = setting.spoilerBodyHeight + 'px';
		setting.spoilerBody.classList.remove('is-open');
	}

	function toggle(){
		if(setting.isCollapse){
			showSpoiler();
			setting.isCollapse = false;
		}
		else {
			hideSpoiler();
			setting.isCollapse = true;
		}

		setTimeout(function(){
			if(setting.onToggleEvent !== null){
				setting.onToggleEvent();
			}
		}, 350);
	}

	function onClickEvent(event){
		event.preventDefault();
		toggle();
	}

	function setEvent(){
		element.addEventListener('click', onClickEvent);

		for (var i = 0; i < setting.relativeElements.length; i++) {
			setting.relativeElements[i].addEventListener('click', onClickEvent);
		}
		window.addEventListener('resize', _throttle(update, 100));

		$('[data-toggle="tab"]').on('shown.bs.tab', function (e) {
			update();
		});

		document.addEventListener('ajaxModalLoad', function(){
			update();
		});
		document.addEventListener('modalOpen', function(){
			update();
		});
	}

	init();

	return API;
}


export default Spoiler;
