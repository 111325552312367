import $ from 'jquery';

(function(){
	var CheckForm = function(element, option){
		this.option = option;
		this.element = element;
		this.$element = $(element);

		this.timeout;
		this.reset_time = 2000;

		this.items = [];

		this.init();
	}

	CheckForm.prototype = {
		init: function(){
			var _this = this;

			this.errors_count = null;
			this.items = [];

			this.$element_form = this.$element.find('> form');
			this.$items = this.$element.find('.req');
			this.$items_wrap = this.$element.find('.form-card__body');

			this.$submit_btn = this.$element.find('[data-submit]');
			this.$reset_btn = this.$element.find('.reply-fc');
			this.$alert_block = this.$element.find('.form-card__ctrl-alert .alert');

			this.reply = this.$element.attr('data-reply');

			this.getItems();

			if (this.items == "") {
				return false
			}

			this.checkItemsStatus();

			this.setFormStatus();
			this.setBtnStatus();

			this.calcErrors();

			this.setEvents();
		},

		update: function(){
			this.destroy();
			this.init()
		},

		destroy: function(){
			for(var i = 0; i < this.items.length; i++){
				this.items[i].unbind('focusin.checkform focusout.checkform change.checkform');
				this.items[i].removeClass('is-error is-success');
			}
			this.$submit_btn.unbind('click.checkform').removeClass('is-disable');
			this.$reset_btn.unbind('click.checkform');
		},

		getItems: function(){
			//console.log('get items')
			var _this = this;

			_this.$items.each(function(key){
				$(this).data('index', key);
				_this.items.push($(this));
				_this.items[key]['index'] = key;
				_this.items[key].messages = JSON.parse(this.getAttribute('data-errors'));
				_this.items[key].messageEl = this.parentNode.querySelector('[data-input-message]');


				_this.setItemType(key);
				//_this.setItemStatus(item);
			});
		},

		validate: function(){
			this.checkItemsStatus();

			this.setFormStatus();
			this.setBtnStatus();

			for (var i = 0; i < this.items.length; i++) {
				this.setItemStyle(i);
			}

			this.calcErrors();
		},

		validateItem: function($item){
			var index = $item.data('index');

			this.setItemStatus(index);
			this.setFormStatus();
			this.setBtnStatus();
			this.setItemStyle(index);
			this.calcErrors();
		},

		checkItemsStatus: function(){
			for (var i = 0; i < this.items.length; i++) {
				this.setItemStatus(i);
			}
		},

		setItemStatus: function(index){
			this.items[index]['success'] = this.checkInput(index);
		},

		setItemType: function(index){
			if (this.items[index].attr('type') == 'checkbox' || this.items[index].attr('type') == 'radio') {
				this.items[index]['type'] = 'check';
			}
			else if(this.items[index].attr('data-type') == 'rate'){
				this.items[index]['type'] = 'rate';
			}
			else {
				this.items[index]['type'] = 'text';
			}

			if(this.items[index][0].nodeName == 'SELECT'){
				this.items[index]['type'] = 'select';
			}
		},

		setFormStatus: function(){
			for(var i = 0; i < this.items.length; i++){
				if (!this.items[i]['success']){
					this.form_status = false;
					this.element.validateStatus = false;
					this.$element.trigger('checkForm.unvalidated');
					break;
				}
				this.form_status = true;
				this.element.validateStatus = true;
				this.$element.trigger('checkForm.validated');
			}
		},

		setBtnStatus: function(){
			if(this.form_status){
				this.btn_status = true;
				this.$submit_btn.removeClass('is-disable');
			}
			else {
				this.btn_status = false;
				this.$submit_btn.addClass('is-disable');
			}
		},

		setEvents: function(){
			var _this = this;

			_this.items.forEach(function(item){
				switch (item['type']) {
					case 'text':
						item.on({
							'focusin.checkform': function(index){
								return function(){
									_this.removeItemStyle(index);
									if (_this.errors_count <= 1 && _this.items.length !== 1) {
										$(this).on('keyup.checkform', function(){
											_this.removeItemStyle(index);
											_this.setItemStatus(_this.items[index]['index']);
											_this.setFormStatus();
											_this.setBtnStatus();
											_this.setItemStyle(index);
										}).on('focusout.checkform.spec', function(){
											$(this).off('focusout.checkform.spec');
											$(this).off('keyup.checkform');
										});
									}
								}
							}(item.index),
							'focusout.checkform': function(index){
								return function(){
									_this.setItemStatus(_this.items[index]['index']);
									_this.setFormStatus();
									_this.setBtnStatus();
									_this.setItemStyle(index);

									_this.calcErrors();
								}
							}(item.index)
						})
					break

					case 'check':
						item.on('change.checkform', function(index){
							return function(){
								_this.setItemStatus(_this.items[index]['index']);
								_this.setFormStatus();
								_this.setBtnStatus(index);
								_this.removeItemStyle(index);
								_this.setItemStyle(index);
								_this.calcErrors();
							}
						}(item.index))
					break

					case 'select':
						item.on('change.checkform', function(index){
							return function(){
								_this.setItemStatus(_this.items[index]['index']);
								_this.setFormStatus();
								_this.setBtnStatus(index);
								_this.removeItemStyle(index);
								_this.setItemStyle(index);
								_this.calcErrors();
							}
						}(item.index))
					break
				}
			});

			this.$submit_btn.on('click.checkform', function(){
				if (this.btn_status) {
					_this.submitForm();
				}
			})
			this.$submit_btn.on('click.checkform', function(){
				if (this.reply) {
					_this.resetForm();
				}
			})
			//console.log('end set event')
		},

		checkInput: function(index){
			var inputSelectType = this.items[index].attr('data-type');

			if (this.items[index]['type'] == "text" && this.items[index][0].value == "") {
				if(this.items[index].messages && this.items[index].messages.required){
					this.setMessage(this.items[index], this.items[index].messages.required);
				}
				return false;
			}
			else if (this.items[index]['type'] == "check"){
				return this.items[index][0].checked;
			}
			else if (this.items[index]['type'] == "select" && this.items[index][0].value == ''){
				return false;
			}
			else {
				switch (inputSelectType) {
					case 'email':
						var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
						if( pattern.test(this.items[index][0].value) ){
							return true;
						}
						else {
							if(this.items[index].messages && this.items[index].messages.incorrect){
								this.setMessage(this.items[index], this.items[index].messages.incorrect);
							}
							return false;
						}
					break

					case 'phone':
						var pattern = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/i;
		                if(!pattern.test(this.items[index][0].value)){
		                	return false
		                }
		                else {
		                	return true
		                }
					break

					case 'radio':
						var radio_name = this.items[index].attr('name');
						for (index in this.items){
							if (this.items[index].attr('name') == radio_name){
								this.items[index]['success'] = true;
							}
						}
						return true;
					break

					case 'checkbox':
						if(!this.items[index]['success']){
							return true;
						}
						else
							return false;
					break

					default:
						return true;
					break
				}
			}
		},

		setMessage: function(item, message){
			if(item.messageEl){
				item.messageEl.innerHTML = message;
			}
		},

		removeItemStyle: function(index){
			this.items[index].removeClass('is-success is-error');
		},

		setItemStyle: function(index){
			if (this.items[index]['success']) {
				this.items[index].addClass('is-success');
			}
			else if (!this.items[index]['success']) {
				this.items[index].addClass('is-error');
			}
		},

		submitForm: function(){
			var _this = this;
			this.$alert_block.removeClass('hide');

			if(this.reply) {
				this.$items_wrap.addClass('hide');
				this.$submit_btn.addClass('hide');
				this.$reset_btn.removeClass('hide');

				this.timeout = setTimeout(function(){
					_this.resetForm();
				}, this.reset_time);
			}
		},

		resetForm: function(){
			clearTimeout(this.timeout);

			this.$alert_block.addClass('hide');
			this.$items_wrap.removeClass('hide');

			this.$submit_btn.removeClass('hide');
			this.$reset_btn.addClass('hide');

			this.$element_form.trigger('reset');

			this.$element.find('input, textarea').removeClass('is-active');
			this.$element.find('.txt-input__field-remove').removeClass('is-show');

			this.setFormStatus();
			this.setBtnStatus();
		},

		calcErrors: function(){
			this.errors_count = 0;
			for (var i = 0; i < this.items.length; i++) {
				if(this.items[i]['success'] == false){
					this.errors_count ++;
				}
			}
			//console.log(this.errors_count);
		}
	}

	$.fn.checkForm = function (option, param) {
		return this.each(function () {
			var $this = $(this),
				data  = $this.data('my.checkForm');

			if (!data) $this.data('my.checkForm', (data = new CheckForm(this, option)));
			if (typeof option == 'string') data[option](param);
		});
	}
})();
