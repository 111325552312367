var ProgressBar = function(container, startIndex, totalLength){
	var API = {},
		setting = {};

	function init(){
		setting.startIndex = startIndex;
		setting.totalLength = totalLength;
		setTemplate();
		setBarPosition(setting.startIndex);
	}

	function setTemplate(){
		var template = '<div class="progress"><div class="progress__bar" data-progress-bar></div></div>';
		container.innerHTML = template;

		setting.progressBar = container.querySelector('[data-progress-bar]');
	}

	function setBarPosition(position){
		var progressWidth = (position + 1) / setting.totalLength * 100;
		setting.progressBar.style.width = progressWidth + '%';
	}

	function update(startIndex, totalLength){
		setting.startIndex = startIndex;
		setting.totalLength = totalLength;
		setBarPosition(setting.startIndex);
	}

	init();

	API.setPosition = setBarPosition;
	API.update = update;
	return API;
}

export default ProgressBar;