	var instance;
	/*
		SliderWatcher - обертка над функциями инита и дестроя. Используется в ResizeStateWatcher.

		runOnDesctop - вызывается на десктопном разрешении
		runOnMobile - на мобилном разрешении
		isReverse - реверсное поведение()
		breakPosition - позиция перключения. необязателен
	*/
	var SliderWatcher = function (runOnDesctop, runOnMobile, isReverse, breakPosition) {
		var watcher = {};

		function init() {
			if (isReverse == undefined) {
				isReverse = false;
			}

			watcher.runOnDesctop = runOnDesctop;
			watcher.runOnMobile = runOnMobile;
			watcher.isInit = isReverse;
			watcher.breakPosition = breakPosition ? breakPosition : 768;
		};

		init();
		return watcher;
	}

	/*
		ResizeStateWatcher - следит за инитом и дестроем сущностей при ресайзе.

		breakPosition - устанавливает позицию брякпоинта. По умолчанию мобильная(768)

		методы:
		add - добавляет экземпляр SliderWatcher;
	*/
	var ResizeStateWatcher = function () {
		if (instance) { return instance; }

		var API = {},
			sliderWatchers = [],
			isMobileRun,
			windowWidth = window.innerWidth;

		function init() {
			setEvents();
			checkWatchers();
		}

		function addSliderWatcher(watcher) {
			if (!watcher || !watcher.runOnMobile || !watcher.runOnDesctop) { return }
			sliderWatchers.push(watcher);
			checkWatcher(watcher);
		}

		function checkWatcher(watcher) {
			if (checkBreakpoint(watcher.breakPosition)) {
				if (!watcher.isInit) {
					watcher.runOnMobile();
					watcher.isInit = true;
				}
			}
			else {
				if (watcher.isInit) {
					watcher.runOnDesctop();
					watcher.isInit = false;
				}
			}
		}

		function checkWatchers() {
			for (var i = 0; i < sliderWatchers.length; i++) {
				checkWatcher(sliderWatchers[i]);
			}
		}

		function onResize() {
			windowWidth = window.innerWidth;
			checkWatchers();
		}

		function checkBreakpoint(position) {
			return windowWidth < position;
		}

		function setEvents() {
			window.addEventListener('resize', throttle(onResize, 120));
		}

		init();

		API.add = addSliderWatcher;

		instance = API;
		return API;
	}

	var resizeStateWatcher = new ResizeStateWatcher();

export {SliderWatcher, resizeStateWatcher};
