import '../libs/swiper/swiper.min';
import ProgressBar from './ProgressBar';

function init(){
	var containers = document.querySelectorAll('.js-base-slider');

	for (var i = 0; i < containers.length; i++) {
		initSlider(containers[i]);
	};
}

function initSlider(container){
	if(!container){return}
	var nav = {};

	nav.prev = container.querySelector('[data-slider-prev]');
	nav.next = container.querySelector('[data-slider-next]');
	nav.progress = container.querySelector('[data-slider-progress]');

	var slider = new Swiper(container, {
		speed: 600,
		slidePrevClass: 'is-prev',
		slideActiveClass: 'is-active',
		slideNextClass: 'is-next',
		slidesPerView: 1,
		spaceBetween: 100,
		watchOverflow: true,
		centeredSlidesBounds: true,
		breakpoints: {
			960: {
				spaceBetween: 34,
			},
		},
		navigation: {
			nextEl: nav.next,
			prevEl: nav.prev,
			disabledClass: 'is-disable'
		},
		on:{
			init: function(){
				if(nav.progress){
					this.progressBar =  new ProgressBar(nav.progress, this.realIndex, this.slides.length);
				}
			},
			slideChange: function(){
				if(nav.progress){
					this.progressBar.setPosition(this.realIndex);
				}
			},
		}
	});
	container.swiperSlider = slider;
}

export default init;