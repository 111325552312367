function requestAjax(option) {
	/*
		option.url - путь, куда отпралять запрос
		option.data - данные с формы
		option.onSuccess - callback на упешный аякс
		option.onError - callback - на ошибочный
	*/

	$.ajax({
		url: option.url,
		method: 'post',
		data: option.data,
		dataType: 'json',
		contentType: false,
		processData: false,
		success: function (response) {
			option.onSuccess(response);
		},
		error: function (response) {
			console.error(response);
			option.onError(response);
		}
	});
};

export default requestAjax;