var WINDOW_HEIGHT = window.innerHeight;
var WINDOW_WIDTH = window.innerWidth;

function onResize(){
    WINDOW_HEIGHT = window.innerHeight;
    WINDOW_WIDTH = window.innerWidth;
}
window.addEventListener('resize', throttle(onResize, 50));

export {WINDOW_HEIGHT, WINDOW_WIDTH}
